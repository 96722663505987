@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
.App {
  text-align: center;
}
body,
h1,
.ui.header {
  color: #4a4a4a;
}
h1,
.ui.header {
  font-family: "Oswald", sans-serif;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainCard > video {
  margin: 3px;
  max-width: 99%;
}

.miniVideo {
  margin: 10px auto 10px auto !important;
}

.chatBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  z-index: 1;
}

.mainCard {
  z-index: 2;
}

.ui.segment:last-child {
  margin-bottom: 5px;
}

.volumeIndicator {
  border: 1px solid black;
}

.rt-tbody {
  padding-bottom: 80px;
}

.react-datepicker__time-list {
  padding: 0;
}

.approvedBooking {
  background-color: #8ddb8c !important;
}

.pendingBooking {
  background-color: #60b9ff !important;
}

.rejectedBooking {
  background-color: #bf5755 !important;
}

.requestAssistance {
  border: 2px solid red !important;
}

#selfieContainer {
  width: 640px;
  height: 480px;
}

#cameraBlock {
  width: 100%;
  position: relative;
}

.slidecontainer {
  width: 100%;
}

.sliderCounter {
  display: inline-block;
  width: 10%;
  font-family: "Oswald", sans-serif;
  padding: 5px;
}

.sliderLabel {
  font-family: "Oswald", sans-serif;
}

.slider {
  -webkit-appearance: none;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  display: inline-block;
  width: 90%;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #0089a8;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #0089a8;
  cursor: pointer;
}

#cameraBlock #start-camera {
  z-index: 999;
  display: none;
  opacity: 0.8;
  margin: 50px auto;
  text-align: center;
  top: 180px;
  position: absolute;
}

#cameraBlock video#camera-stream {
  display: none;
  width: 100%;
}

#cameraBlock img#snap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
}

#cameraBlock .controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 30px;
  display: none;
}

#cameraBlock .controls a {
  border-radius: 50%;
  color: #fff;
  background-color: #111;
  text-decoration: none;
  padding: 15px;
  line-height: 0;
  opacity: 0.7;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

#cameraBlock .controls a:hover {
  opacity: 1;
}

#cameraBlock .controls a.disabled {
  background-color: #555;
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

#cameraBlock .controls a.disabled:hover {
  opacity: 0.5;
}

#cameraBlock .controls a i {
  font-size: 18px;
}

#cameraBlock .controls #take-photo i {
  font-size: 32px;
}

#cameraBlock canvas {
  display: none;
}

#cameraBlock video#camera-stream.visible,
#cameraBlock img#snap.visible {
  display: block;
}

#cameraBlock .controls.visible {
  display: flex;
}

#cameraBlock #start-camera.visible {
  display: block;
}

#faceOverlay {
  position: absolute;
  width: 50%;
  top: 109px;
  left: 168px;
  display: none;
}

#cameraBlock #faceOverlay.visible {
  display: block;
}

.ui.menu img {
  width: 200px !important;
}

.ui.positive.button {
  background-color: #6bc048 !important;
}

.ui.progress.success .bar {
  background-color: #6bc048 !important;
}
.ui.button,
.ui.primary.button,
.ReactTable .-pagination .-btn {
  background-color: #0089a8;
  color: white;
}
.ReactTable {
  margin-bottom: 50px;
}
.ui.green.button {
  background-color: #6bc048 !important;
}
.feed {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}
.extra.content .button {
  margin: 0 5px 5px;
  background-image: linear-gradient(
    to right,
    #0089a8 0%,
    #64c048 50%,
    #0089a8 100%
  );
  background-size: 200% auto;
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  transition: 0.5s;
  color: white;
  font-size: 16px;
}
.extra.content .button:hover {
  background-position: right center;
  color: black;
}
.volumeIndicator {
  width: 95% !important;
  margin: 10px auto 0;
  border: #f2f2f2;
  border-radius: 5px;
  height: 36px;
  padding: 5px !important;
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}
.ui.raised.card {
  height: max-content;
}
.extra .column {
  text-align: center;
}
.controls {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .ui.grid > .row > [class*="five wide"].column {
    width: 100% !important;
  }
  .volumeIndicator {
    width: 100%;
  }
  .ui.raised.card {
    right: 20%;
  }
}

/* Dashboard UI Changes*/
.ui.menu .item:before {
  background: none;
}
.ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)
  > .container
  > .item:not(.right):not(.borderless):first-child {
  border-left: none;
}
.ui.menu .active.item {
  background: none;
}
.header-menu {
  background: #0089a8 !important;
  border: none !important;
  box-shadow: none !important;
}
.grad-bar {
  height: 25px;
  background-color: #64c048;
  background-image: linear-gradient(to right, #0089a8, #64c048, #2c3c4c);
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.ui.cards > .card > .card-header,
.ui.card > .card-header {
  background-color: #2c3c4c;
  border-top: none;
  flex-grow: 0;
}

.ui.card > .card-header > .header {
  color: white;
  vertical-align: middle;
  display: inline;
}
.ui.card > .content,
.ui.cards > .card > .content {
  border: 3px solid #2c3c4c;
  border-radius: 10px;
  outline: none;
}
.ui.card > .content i,
.ui.cards > .card > .content i {
  color: #0089a8;
}

.home-buttons .ui.button {
  font-size: 22px;
  line-height: 28px;
  max-width: 175px;
  border-radius: 10px;
  min-height: 125px;
}
.home-buttons .ui.button:hover {
  background: #0089a8 !important;
}
.home-buttons .ui.button i.icon {
  margin: 5px 0 0 0 !important;
}
.ui.home-divider:not(.vertical):not(.horizontal) {
  border-top: 5px solid #21ba45;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.ui.home-message {
  background: #0089a8;
  color: white;
  border: none;
  box-shadow: 5px 5px 7px 0px #0000004a;
}
.ui.home-message a {
  color: #ffb420;
}
.ui.home-exam {
  border: 3px solid #0089a8;
  text-align: center;
  background: #0089a845;
}
.exam-block-title {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}
.ui.exam-block-divider:not(.vertical):not(.horizontal) {
  border-top: 3px solid #21ba45;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.min-reqs {
  width: 100%;
  background: #2c3c4c;
  color: white;
  padding: 50px;
}
.downloads {
  width: 100%;
  background: #efefef;
  color: #4a4a4a;
  padding: 50px;
  margin-top: 25px;
}
.instructions .icon {
  font-size: 45px;
  color: #2c3c4c;
}
.instructions .segment {
  border: none;
  display: flex;
  align-items: center;
  min-height: 160px;
  padding: 50px 20px;
  box-shadow: 1px 1px 8px 0px rgba(34, 36, 38, 0.7);
}
.instructions .content {
  display: contents;
}
.inst-icon {
  text-shadow: 0px 1px 6px #64c048;
  width: 20%;
  vertical-align: middle;
}
.inst-content {
  width: 75%;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 10px;
}
.inst-icon i:before {
  vertical-align: text-bottom;
}
.downloads .column {
  display: flex;
  text-align: center;
  align-items: center;
}
.downloads .icon {
  font-size: 100px;
  transition-property: transform;
  color: #2c3c4c;
}
.downloads .button {
  background: #64c048;
  margin-top: 25px;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}
.downloads i:before {
  vertical-align: text-bottom;
}
.ui.admin-menu-tabs {
  background-image: -webkit-linear-gradient(left, #0089a8, #64c048, #2c3c4c);
}
.ui.admin-menu-tabs .item {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.ui.admin-menu-tabs .active.item {
  background-color: #f2f2f2;
}
.ui.admin-menu-tabs .active.item:after {
  border-bottom: 1px solid #64c048;
  border-right: 1px solid #64c048;
}
.rbc-event {
  background-color: #0089a8;
}
.ui.modal > .header:not(.ui) {
  background: #0089a8;
  color: white;
}
.exams-list {
  padding-left: 0px;
  margin: 1px;
  list-style-type: none;
}
.name-badge {
  background: #0089a8;
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: -10px;
  text-align: center;
  font-size: 22px;
}
.name-small {
  padding: 5px;
  font-size: 14px;
}

@keyframes border-pulse {
  50% {
    border-color: rgba(255, 255, 255, 0);
  }
}

.ui.incoming {
  border: 12px solid rgb(100, 192, 72);
  animation: border-pulse 1s infinite;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .inst-content,
  .inst-icon {
    width: 100%;
    display: block;
    text-align: center;
  }
  .ui.instructions > .row > .column {
    width: 100% !important;
  }
}

.disconnected-message {
  text-align: center;
  color: #0089a8 !important;
  background: none !important;
  box-shadow: none !important;
}
/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */

.dots-container {
  margin-left: 1em;
  padding: 0.3em 0.78571429em;
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #efefef;
  color: #efefef;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #efefef;
  color: #efefef;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #efefef;
  color: #efefef;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
#chatFeed .segment {
  border: 1px solid #0089a8 !important;
  border-bottom: 5px solid #0089a8 !important;
  width: 90%;
  margin: 20px auto !important;
}

.check-sidebar #chatFeed {
  width: 100% !important;
}
